<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small"
                        @click="config.isDetailDisplay = false">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="editApi"
                        v-if="!config.isAdd && config.isCanEdit" v-right-code="'Deviceinfo:Edit'">编辑</el-button> </div>
                <div class="h-b"><el-button type="primary" size="small" @click="saveApi" v-if="config.isAdd" v-right-code="'Deviceinfo:Edit'">保存</el-button>
                </div>
            </div>
        </div>

        <el-tabs type="border-card" v-model="activeName">
            <el-tab-pane name="tabbasic">
                <span slot="label">基本信息</span>
                <div class="form-list">
                    <el-form :model="dataSource" ref="_editForm" :rules="validateRule">
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>设备编码：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="Code">
                                    <el-input v-model="dataSource.Code" :maxlength="100" :minlength="1"
                                        placeholder="设备编码" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>设备名称：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="Name">
                                    <el-input v-model="dataSource.Name" :maxlength="100" :minlength="1"
                                        placeholder="设备名称" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>设备类型：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="DeviceType">
                                    <DictionarySelect v-model="dataSource.DeviceType" datType="Int"  dicTypeCode="DeviceTypeCode" :disabled="!config.isAdd"></DictionarySelect>
                                </el-form-item>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                批号：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="BatchNo">
                                    <el-input v-model="dataSource.BatchNo" :maxlength="100" :minlength="1"
                                        placeholder="批号" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>型号：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="DeviceMode">
                                    <LookPopup v-model="dataSource.DeviceMode" dataTitle="型号选择"
											dataUrl='omsapi/modelinfo/getpagelist' syncField='DeviceMode'
											@syncFieldEvent="syncModeEvent" 
                                            :dataOptions="deviceModeOption"
                                            :disabled="!config.isAdd">
										</LookPopup>
                                </el-form-item>
                            </el-col>

                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>生产日期：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="ProductDate">
                                    <el-date-picker  v-model.trim="dataSource.ProductDate" size="mini"
									type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>购入日期：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="BuyDate">
                                    <el-date-picker  v-model.trim="dataSource.BuyDate" size="mini"
									type="date" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                GPS设备终端ID：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="BatchNo">
                                    <el-input v-model="dataSource.GpsTerminalId" :maxlength="100" :minlength="1"
                                        placeholder="" disabled readonly></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">
                                SIM卡号：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="BatchNo">
                                    <el-input v-model="dataSource.SimNo" :maxlength="100" :minlength="1"
                                        placeholder="SIM卡号" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-col :span="3" class="form-title">备注：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="Remark">
                                    <el-input type="textarea" v-model.trim="dataSource.Remark" :maxlength="200">
										</el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>

                <el-collapse v-model.trim="collapseItem">
                    <el-collapse-item title="其他信息" name="otherinfo">
                        <el-form :model="dataSource">
                            <el-form-item>
                            <el-col :span="3" class="form-title">所属客户：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="CustomerVendorName">
                                    <LookPopup v-model="dataSource.CustomerVendorName" dataTitle="供应商选择"
											dataUrl='omsapi/customervendor/getlist' syncField='CustomerVendorName'
											@syncFieldEvent="syncCusVendorEvent" >
										</LookPopup>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">专属项目：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="CustomerVendorName">
                                    <LookPopup v-model="dataSource.ProjectName" dataTitle="专属项目选择"
											dataUrl='omsapi/projectinfo/getpagelist' syncField='ProjectName'
											@syncFieldEvent="syncProjectEvent" >
										</LookPopup>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">项目别名：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="ProjectOtherName">
                                    <el-input v-model="dataSource.ProjectOtherName" :maxlength="200" placeholder="项目别名" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">设备厂商：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="FactoryName">
                                    <LookPopup v-model="dataSource.FactoryName" dataTitle="供应商选择"
											dataUrl='omsapi/customervendor/getlist' syncField='FactoryName'
											@syncFieldEvent="syncFactoryEvent" >
										</LookPopup>
                                </el-form-item>
                            </el-col>

                            <el-col :span="3" class="form-title">温度计设备厂商：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="ThermometerFactoryName">
                                    <LookPopup v-model="dataSource.ThermometerFactoryName" dataTitle="温度计设备厂商"
											dataUrl='omsapi/customervendor/getlist' syncField='ThermometerFactoryName'
											@syncFieldEvent="syncThermometerEvent" >
										</LookPopup>
                                </el-form-item>
                            </el-col>

                            <el-col :span="3" class="form-title">温度计设备编号：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="ThermometerDeviceNo">
                                    <el-input v-model="dataSource.ThermometerDeviceNo" :maxlength="200" placeholder="温度计设备编号" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-col :span="3" class="form-title">内尺寸(mm)：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item>
                                    <el-input size="mini" type="number" v-model="dataSource.InnerLength"  placeholder="长" :disabled="!config.isAdd"></el-input>
                                    X
                                    <el-input size="mini" type="number" v-model="dataSource.InnerWidth"  placeholder="宽" :disabled="!config.isAdd"></el-input>
                                    X
                                    <el-input size="mini" type="number" v-model="dataSource.InnerHeight"  placeholder="高" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">使用年限：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="Life">
                                    <el-input type="number" v-model="dataSource.Life"  placeholder="使用年限" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>

                        <el-form-item>
                            <el-col :span="3" class="form-title">重量(kg)：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="Weight">
                                    <el-input type="number" v-model="dataSource.Weight"  placeholder="重量" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">容积(L)：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="ContainerSize">
                                    <el-input type="number" v-model="dataSource.ContainerSize"  placeholder="容积" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">体积(m³)：
                            </el-col>
                            <el-col :span="4">
                                <el-form-item prop="Volume">
                                    <el-input type="number" v-model="dataSource.Volume"  placeholder="体积" :disabled="!config.isAdd"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>

                        </el-form>
                    </el-collapse-item>
                </el-collapse>
            </el-tab-pane>

        </el-tabs>
    </div>
</template>
<script>
export default {
    mounted() {
    },
    data() {
        return {
            activeName: "tabbasic",
            collapseItem: ['otherinfo'],
            validateRule: {
                Name: [{ required: true, message: '请输入车型名称', trigger: 'blur' }],
                RateVolume: [{ required: true, message: '请输入额定体积', trigger: 'blur' }],
                RateWeight: [{ required: true, message: '请输入额定载重', trigger: 'blur' }],
                DeviceMode:[{ required: true, message: '请选择型号', trigger: 'blur' }],
            },
            deviceModeOption:{Status:100},
            dataSource: {

            },
        };
    },
    props: {
        config: {
            isDetailDisplay: false,
            dataSourceKey: null,
        },
    },
    watch: {
        'config.dataSourceKey': {
            handler(curVal, oldVal) {
                if (curVal && curVal > 0) {
                    if (curVal != oldVal) this.getDetail(curVal); //初始化
                }
            },
            deep: true
        },
    },
    methods: {
        add() {
            var _this = this;
            this.$ajax.send("omsapi/deviceinfo/getempty", "get", {}, (data) => {
                _this.dataSource = data.Result;
            });
        },
        editApi() {
            this.config.isAdd = true;
        },
        detailApi(apiId) {
            this.config.isAdd = false;
        },
        getDetail(apiId) {
            var _this = this;
            this.$ajax.send("omsapi/deviceinfo/get", "get", { id: apiId }, (data) => {
                _this.dataSource = data.Result;
                // _this.$nextTick(function () {
                // });
            });
        },
        saveApi() {
            this.$refs["_editForm"].validate((valid) => {
                if (valid) {
                    var actionName = this.dataSource.Id && this.dataSource.Id > 0 ? 'edit' : 'create';
                    var obj = this.Utils.cloneObj(this.dataSource);
                    this.$ajax.send("omsapi/deviceinfo/" + actionName, "post", obj, (data) => {
                        this.config.dataSourceKey = data.Result;
                        this.dataSource.Id = data.Result;
                        this.config.isCanEdit = false;
                        this.config.isAdd = false;
                        //this.detailApi(curVal)
                        this.Event.$emit("reloadRolePageList");
                        this.Utils.messageBox("保存成功.", "success");
                    });
                } else {
                    this.Utils.messageBox("存在必填信息未输入.", "error");
                    return false;
                }
            });
        },
        syncCusVendorEvent(row){
            if (row) {
					this.dataSource.CustomerVendorId = row.VendorId;
					this.dataSource.CustomerVendorName = row.VendorName;
				}
        },
        syncModeEvent(row){
            if (row) {
					this.dataSource.DeviceMode = row.Name;
				}
        },
        syncProjectEvent(row){
            if (row) {
					this.dataSource.ProjectId = row.Id;
					this.dataSource.ProjectName = row.Name;
				}
        },
        syncFactoryEvent(row){
            if (row) {
					this.dataSource.FactoryId = row.VendorId;
					this.dataSource.FactoryName = row.VendorName;
				}
        },
        syncThermometerEvent(row){
            if (row) {
					this.dataSource.ThermometerFactoryId = row.VendorId;
					this.dataSource.ThermometerFactoryName = row.VendorName;
				}
        },
    },
    components: {
    }
}
</script>